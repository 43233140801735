<template>
  <div>

    <div class="common">
      <div class="common-header">
        <div class="serch">
          <div class="select-serch">
            <div style="margin-top: 15px;">
              <el-input placeholder="请输入内容" v-model="input" class="input-with-select" clearable @clear="fetchDataByOne">
                <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 120px">
                  <el-option label="人员编码" value="1"></el-option>
                  <el-option label="用户名" value="2"></el-option>

                </el-select>
                <el-button slot="append" icon="el-icon-search" @click="clickFetchData"></el-button>
              </el-input>
            </div>
          </div>
        </div>
        <div class="tow-button">
          <div class="button">
            <add-user-dialog @formSubmitted="catchAddEvent"></add-user-dialog>
          </div>
          <div class="button">
            <el-button type="primary" @click="downloadUserTemplateRequest"
                       :loading="isDownload"
                       :disabled="isDownload">下载模板</el-button>
          </div>
          <div class="button">
            <input
                type="file"
                ref="fileInput"
                style="display: none"
                @change="handleFileChange"


            />

            <el-button type="primary" @click="triggerFileSelect"
                       :loading="isUploading"
                       :disabled="isUploading"

            >批量上传</el-button>
          </div>
        </div>

      </div>
      <div class="common-middle">
        <div class="bar"></div>
        <div class="table">
          <el-table
              :data="tableData"
              style="width: 100%"
              border>
            <el-table-column
                width="70px"
                prop="n"
                label="序号">
              <template slot-scope="scope">
                {{ ( scope.$index + 1).toString().padStart(2, '0') }}
              </template>
            </el-table-column>
            <el-table-column
                prop="userCode"
                label="人员编码"
            >
            </el-table-column>
            <el-table-column
                prop="username"
                label="用户名"
            >
            </el-table-column>
            <el-table-column
                prop="name"
                label="姓名"
            >
            </el-table-column>
            <el-table-column
                prop="role"
                label="角色"
            >
            </el-table-column>
            <el-table-column
                prop="createTime"
                label="创建时间"
            >
            </el-table-column>
            <el-table-column
                prop="updateTime"
                label="更新时间"
            >
            </el-table-column>



            <el-table-column

                label="操作"
                width="100px"
            >

              <template slot-scope="scope">
                <edit-user  :row="scope.row" @formEdit="catchEditEvent" @formDelete="catchDeleteEvent"></edit-user>

              </template>

            </el-table-column>


          </el-table>
        </div>

      </div>
      <div class="common-foot">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            class="custom-pagination"
            style="margin-left: auto;margin-top: 15px;margin-right: 15px">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* 样式可以根据需要添加 */

.custom-pagination >>> .el-pagination__total {
  color: black;
}

.custom-pagination >>> .el-pagination__jump {
  color: black;
}

.common{
  width: 100%;
  height: 100%;

}

.common .common-header{
  width: 100%;


  display: flex;
  justify-content: flex-start; /* 水平靠左排列 */
  align-items: flex-end;
  flex-direction: column;
}
.common .common-header .serch{
  background: #4B6EA8;
  width: 100%;
  height: 80px;

  display: flex;
  justify-content: center; /* 水平居中 */

}
.common .common-header .serch .select-serch{
  width: 500px;
  height: 70px;

}


.common .common-header .tow-button{
  width: 100%;
  height: 80px;

  display: flex;
  justify-content: flex-start; /* 水平靠左排列 */
  align-items: flex-end; /* 垂直靠下排列 */
}

.common .common-header .tow-button .button{
  width: 100px;
  height: 30px;

  margin-bottom: 15px;
  margin-left: 15px;
}

.common .common-middle{
  width: 100%;
  height: 100%;

  display: flex;

  flex-direction: column;

}

.bar{
  width: 100%;
  height: 50px;
  background: #4B6EA8;
}


.common .common-middle .table{
  width: 90%;
  min-width: 1000px;
  margin-right: auto;
  margin-left: auto;


}
.common .common-foot{
  background: #4B6EA8;
  width: 100%;
  display: flex;
}



</style>


<script>

import AddUserDialog from "@/components/AddUserDialog.vue";
import EditUser from "@/components/EditUser.vue";

export default {
  data(){
    return{
      tableData:[],
      total:0,
      currentPage:1,
      size:10,
      select:'1',
      input:'',

      isUploading:false,
      isDownload:false,
    }
  },
  components:{
    AddUserDialog,
    EditUser,
  },
  mounted() {
    this.fetchData();
  },
  methods:{

    // 点击按钮触发文件选择框
    triggerFileSelect() {
      this.$refs.fileInput.click(); // 触发文件输入框点击
    },
    // 处理文件选择
    handleFileChange(event) {
      const file = event.target.files[0]; // 获取选中的文件
      if (file) {
        this.fileName = file.name; // 保存文件名
        this.uploadFile(file); // 调用上传方法
      }

      // 重置文件输入框的值
      event.target.value = '';
    },
    // 文件上传
    async uploadFile(file) {
      this.isUploading = true; // 开始上传，按钮显示加载状态

      // 创建 FormData 对象
      const formData = new FormData();
      formData.append('file', file); // 将文件加入到表单数据中

      // 使用 Axios 发送 POST 请求
      this.$axios.post(process.env.VUE_APP_BASE_API + '/api/upload/userExcel', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // 设置请求头为 multipart/form-data
        },
      })
          .then(response => {
            // 上传成功的处理
            if(response.data.success){
              this.$message({
                type:"success",
                message:"上传成功",
              });
              this.fetchDataByOne();
            }else {
              this.$message.error("上传失败");
            }


          })
          .catch(() => {
                // 上传失败的处理
                this.$message.error("上传失败");
              }
          ).finally(() => {
        // 无论成功还是失败，最终都还原按钮状态
        this.isUploading = false;
      });
    },



    //临时上传台账
    async uploadOrderStandingFile(file) {
      this.isUploading = true; // 开始上传，按钮显示加载状态

      // 创建 FormData 对象
      const formData = new FormData();
      formData.append('file', file); // 将文件加入到表单数据中

      // 使用 Axios 发送 POST 请求
      this.$axios.post(process.env.VUE_APP_BASE_API + '/api/upload/orderStandingBook', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // 设置请求头为 multipart/form-data
        },
      })
          .then(response => {
            // 上传成功的处理
            if(response.data.success){
              this.$message({
                type:"success",
                message:"上传成功",
              });
              this.fetchDataByOne();
            }else {
              this.$message.error("上传失败");
            }


          })
          .catch(() => {
                // 上传失败的处理
                this.$message.error("上传失败");
              }
          ).finally(() => {
        // 无论成功还是失败，最终都还原按钮状态
        this.isUploading = false;
      });
    },



    downloadUserTemplateRequest(){
      this.isDownload = true;

      this.$axios.get(process.env.VUE_APP_BASE_API+'/api/download/userTemplate',{responseType: 'blob'})
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `userTemplate.xlsx`); // 设置下载文件名
            document.body.appendChild(link);
            link.click();

          })
          .catch(error => {
            console.error('Error:', error);
          }).finally(() => {
        this.isDownload = false;
      });
    },





    //点击按钮查询
    clickFetchData(){
      this.fetchData();


    },
    //查询贴花列表
    fetchData(){
      var params = {};

      if(this.select == ''){
        params = {
          size:this.size,
          currentPage:this.currentPage,
        }
      }

      if(this.select == `1`){
        params = {
          size:this.size,
          currentPage:this.currentPage,
          userCode:this.input
        }
      }

      if(this.select == `2`){
        params = {
          size:this.size,
          currentPage:this.currentPage,
          username:this.input
        }
      }

      this.$axios.get(process.env.VUE_APP_BASE_API+'/api/user/find',{
        params:params
      })
          .then(response => {
            this.tableData = response.data.data.userList;
            this.total = response.data.total;

            if(this.tableData.length == 0 && this.currentPage > 1){
                this.fetchDataByOne();
            }

          })
          .catch(error => {
            console.error('Error:', error);
          });
    },
    //强制将当前查询页设为1
    fetchDataByOne(){
      var params = {};
      this.currentPage = 1;

      if(this.select == ''){
        params = {
          size:this.size,
          currentPage:this.currentPage,
        }
      }

      if(this.select == `1`){
        params = {
          size:this.size,
          currentPage:this.currentPage,
          userCode:this.input
        }
      }

      if(this.select == `2`){
        params = {
          size:this.size,
          currentPage:this.currentPage,
          username:this.input
        }
      }

      this.$axios.get(process.env.VUE_APP_BASE_API+'/api/user/find',{
        params:params
      })
          .then(response => {
            this.tableData = response.data.data.userList;
            this.total = response.data.total;

          })
          .catch(error => {
            console.error('Error:', error);
          });
    },
    handleSizeChange(size){
        this.size = size
        this.fetchData();
    },
    handleCurrentChange(current){
        this.currentPage = current;
        this.fetchData();
    },
    //捕获添加事件
    catchAddEvent(){
      this.fetchDataByOne();

      this.$message({
        message: '添加成功',
        type: 'success'
      });
    },
    //捕获编辑事件
    catchEditEvent(success){
      this.fetchDataByOne();

      if(success){
        this.$message({
          message: '编辑成功',
          type: 'success'
        });
      }else {
        this.$message.error('编辑失败');
      }
    },
    //捕获删除事件
    catchDeleteEvent(success){
      this.fetchDataByOne();

      if(success){
        this.$message({
          message: '删除成功',
          type: 'success'
        });
      }else {
        this.$message.error('删除失败');
      }
    }

  }
}
</script>


